import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { Button } from 'reactstrap';
import { ArrowUp } from 'react-feather';
import classnames from 'classnames';

const Footer = (props) => {
    let footerTypeArr = ['sticky', 'static', 'hidden'];
    return (
        <footer
            className={classnames('footer footer-light px-1', {
                'footer-static':
                    props.footerType === 'static' ||
                    !footerTypeArr.includes(props.footerType),
                'd-none': props.footerType === 'hidden',
            })}
        >
            <p className="mb-0 clearfix text-center">
                <span className="d-block d-md-inline-block mt-25">
                    Copyright © {new Date().getFullYear()}
                    <a
                        href="https://iserviceauto.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        iService Auto,
                    </a>
                    All rights reserved
                </span>
            </p>
            {props.hideScrollToTop === false ? (
                <ScrollToTop showUnder={160}>
                    <Button
                        color="primary"
                        className="scroll-top"
                        onClick={() => {
                            if (props.isMpiVisible) props.showMpi(false);
                            if (props.isQuoteVisible) props.showQuote(false);
                        }}
                    >
                        {props.isMpiVisible ? (
                            'Close Report'
                        ) : props.isQuoteVisible ? (
                            'Go Back'
                        ) : (
                            <ArrowUp size={24} />
                        )}
                    </Button>
                </ScrollToTop>
            ) : null}
        </footer>
    );
};

export default Footer;
