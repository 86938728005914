import clientApi from '../../../clientApi';
import { handleError } from './index';

export const getTrims = (hash) => async (dispatch) => {
    dispatch({ type: 'GET_TRIM' });
    const response = await clientApi.getTrims(hash);
    if (response.status === 200) {
        dispatch({ type: 'RECEIVE_TRIM', data: response.results });
    } else {
        dispatch(handleError(response));
    }
};

export const getConditions = () => async (dispatch) => {
    dispatch({ type: 'GET_CONDITIONS' });
    const response = await clientApi.getConditions();
    console.log(response);
    if (response.status === 200) {
        dispatch({ type: 'RECEIVE_CONDITIONS', data: response.results });
    } else {
        dispatch(handleError(response));
    }
};

export const requestCashOffer = (condition, trim) => async (dispatch) => {
    dispatch({ type: 'REQUEST_CASH_OFFER' });
    const response = await clientApi.requestCashOffer(condition, trim);
    if (response.status === 200) {
        return true;
    } else {
        dispatch(handleError(response));
    }
};