import { showToast, showErrorToast } from '../../../toast';
import clientApi from '../../../clientApi';
import { receiveUnauthorized } from '../auth';
import { handleError, getRO } from './index';

export const getPayment = (linkHash) => async (dispatch) => {
    dispatch({ type: 'GET_PAYMENT' });
    try {
        const paymentResponse = await clientApi.getPayment(linkHash);
        if (paymentResponse.status === 200) {
            dispatch({ type: 'RECEIVE_PAYMENT', data: paymentResponse.data });
        } else {
            throw new Error(paymentResponse.statusText);
        }
    } catch (err) {
        console.error(err);
        dispatch(handleError(err));
    }
};

export const paymentFormErrored = () => (dispatch) => {
    dispatch({ type: 'PAYMENT_FORM_ERRORED' });
};
export const paymentFormLoaded = () => (dispatch) => {
    dispatch({ type: 'PAYMENT_FORM_LOADED' });
};
export const makeRoPayment =
    (repairOrderPaymentId, paymentToken) => async (dispatch) => {
        dispatch({ type: 'MAKE_PAYMENT' });

        try {
            const paymentExists = await dispatch(
                getRoPayment(repairOrderPaymentId)
            );
            if (!paymentExists) {
                return false;
            }
            const body = { repairOrderPaymentId, paymentToken };
            const response = await clientApi.makeRoPayment(body);

            if (response.status === 200) {
                dispatch({ type: 'PAYMENT_SUCCESS' });
                showToast('Thank you for your payment!');

                dispatch(getRO());
            } else {
                throw new Error(`Failed to send payment.`);
            }
        } catch (error) {
            const data = error.response?.data;
            return dispatch(handleError(data?.message ? data : error));
        }
    };
export const viewNonRoPayment = (id) => async (dispatch) => {
    dispatch({ type: 'VIEW_PAYMENT' });

    try {
        await clientApi.viewNonRoPayment({ id });
    } catch (error) {
        const data = error.response?.data;
        return dispatch(handleError(data?.message ? data : error));
    }
};
export const makeNonRoPayment = (id, paymentToken) => async (dispatch) => {
    dispatch({ type: 'MAKE_PAYMENT' });

    try {
        const body = { id, paymentToken };
        const response = await clientApi.makeNonRoPayment(body);

        if (response.status === 200) {
            dispatch({ type: 'PAYMENT_SUCCESS' });
            window.location.href = '/thank-you';
        } else {
            throw new Error(
                `Failed to complete payment. Please refresh and try again or contact your service advisor.`
            );
        }
    } catch (error) {
        const data = error.response?.data;
        return dispatch(handleError(data?.message ? data : error));
    }
};
export const getRoPayments = (repairOrderId) => async (dispatch) => {
    dispatch({ type: 'GET_PAYMENTS' });

    try {
        const response = await clientApi.getRoPayments(repairOrderId);

        if (response.status === 200) {
            dispatch({ type: 'RECEIVE_PAYMENTS', data: response.data.results });
        } else {
            throw new Error(`Failed to get payments.`);
        }
    } catch (error) {
        return dispatch(handleError(error));
    }
};

export const getRoPayment = (paymentId) => async (dispatch, getState) => {
    dispatch({ type: 'GET_PAYMENT' });
    try {
        const response = await clientApi.getRoPayment(paymentId);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const { repairOrder } = getState().dashboard;
        await dispatch(getRoPayments(repairOrder.id));
        if (error?.request?.status === 404) {
            showErrorToast({ message: 'That payment no longer exists!' });
        } else {
            showErrorToast({ message: 'Something went wrong!' });
        }
        return false;
    }
};