import { showErrorToast } from '../../../toast';
import clientApi from '../../../clientApi';
import { receiveUnauthorized } from '../auth';

export const handleError = (error) => (dispatch) => {
    console.error(error);
    if (error.response?.status === 401 || error.response?.status === 403) {
        dispatch(receiveUnauthorized());
    }

    showErrorToast(error);
    dispatch({ type: 'HANDLE_ERROR', error });
};
export const dismissError = () => (dispatch) => {
    dispatch({ type: 'DISMISS_ERROR' });
};

export const getSettings = () => async (dispatch) => {
    dispatch({ type: 'GET_SETTINGS' });

    try {
        const { data } = await clientApi.getSettings();

        dispatch({ type: 'RECEIVE_SETTINGS', data });
    } catch (err) {
        dispatch(handleError(err));
    }
};

export const nowLoading = () => (dispatch, getState) => {
    if (!getState().dashboard.loading) {
        dispatch({ type: 'NOW_LOADING' });
    }
};
export const setLoading = (isLoading) => (dispatch) => {
    if (isLoading === true) dispatch({ type: 'LOADING_START' });
    if (isLoading === false) dispatch({ type: 'LOADING_END' });
};
export const resetDashboardState = () => (dispatch) => {
    dispatch({ type: 'RESET_DASHBOARD_STATE' });
};
