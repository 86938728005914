import { showToast, showErrorToast } from '../../../toast';
import clientApi from '../../../clientApi';
import { handleError, nowLoading, getRO } from './index';

export const requestAppraisal = (repairOrderId) => async (dispatch) => {
    dispatch(nowLoading());

    try {
        const response = await clientApi.requestAppraisal(repairOrderId);

        if (response.status === 200) {
            dispatch({ type: 'APPRAISAL_REQUESTED' });
            showToast('Thank you! Your request for appraisal has been submitted.');
        }
    } catch (err) {
        dispatch(handleError(err));
    }
};

export const submitRating = (repairOrderReview, rating) => async (dispatch) => {
    dispatch(nowLoading());

    try {
        const response = await clientApi.submitRating(
            repairOrderReview.id,
            rating
        );

        if (response.status === 200) {
            dispatch({ type: 'RATING_SUBMITTED' });
            dispatch(getRO());

            showToast('Thank you! Your rating has been submitted.');
        } else {
            throw new Error(
                'Unable to send rating. We greatly value your feedback; please check your internet connection and try again, or contact your service advisor to let us know how we did.'
            );
        }
    } catch (err) {
        dispatch(handleError(err));
    }
};
export const completeQuote = (quoteId, recommendations) => async (dispatch) => {
    dispatch(nowLoading());

    const customerResponses = recommendations
        .filter((rec) => !rec.preApproved)
        .map((rec) => {
            return {
                repairOrderQuoteRecommendationId: rec.id,
                approved: rec.approved === true,
            };
        });

    try {
        const response = await clientApi.completeQuote(
            quoteId,
            JSON.stringify(customerResponses)
        );

        if (response.status === 200) {
            showToast('Thank you! Your quote responses have been submitted.');
            dispatch({ type: 'QUOTE_COMPLETED' });

            dispatch(getRO());
        } else {
            throw new Error(
                'Failed to submit quote; please try again or contact your service advisor.'
            );
        }
    } catch (err) {
        dispatch(handleError(err));
    }
};
export const authorizeVisit = (signature) => async (dispatch, getState) => {
    dispatch(nowLoading());

    const roId = getState().dashboard.repairOrder?.id;

    try {
        const ackRes = await clientApi.acknowledgeWaiver(roId);
        if (ackRes.status !== 200) {
            throw new Error(
                'Authorization failed. Please try again, or contact your service advisor.'
            );
        }

        const sigRes = await clientApi.authorizeVisit(signature, roId);
        if (sigRes.status !== 200) {
            throw new Error(
                'Authorization failed. Please try again, or contact your service advisor.'
            );
        }

        dispatch({ type: 'VISIT_AUTHORIZED' });
        showToast('Thank you! Your visit has been authorized');
    } catch (err) {
        handleError(err);
    }
};

export const sendReceipts = (payments, email) => async (dispatch) => {
    dispatch({ type: 'SEND_RECEIPTS' });

    const promises = payments
        .filter((payment) => !!payment.transactionId)
        .map((payment) => clientApi.sendReceipt(payment.id, email));
    const response = await Promise.all(promises);
    if (response.every((res) => res.status === 200)) {
        return showToast('Successfully sent receipts!');
    }
    return showErrorToast({ message: 'One or more receipts failed to send!' });
};
export const financeRequested = () => (dispatch) => {
    dispatch({ type: 'FINANCE_REQUESTED' });

    showToast('Thank you! Your request for finance has been submitted.');
};