const initialState = {
    isUnauthorized: false, // true on receiving a 401 (or 403 currently)
    linkHash: null,
    loading: false,
    loggedIn: false,
    login: { userRole: 'admin' }, // enables existing parts of vuexy to continue to work (should be removed)
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, loading: true, loggedIn: false };
        case 'RECEIVE_UNAUTHORIZED':
            return { ...state, loading: false, isUnauthorized: true };
        case 'RECEIVE_TOKEN':
            return {
                ...state,
                isUnauthorized: false,
                linkHash: action.linkHash,
                loading: false,
                loggedIn: true,
            };
        case 'RESET_AUTH_STATE':
            return { ...initialState };
        default:
            return state;
    }
};

export default authReducer;
