import { combineReducers } from 'redux';
import auth from './auth/';
import customizer from './customizer/';
import dashboard from './dashboard/';
import navbar from './navbar/Index';

const rootReducer = combineReducers({
    customizer,
    auth,
    navbar,
    dashboard,
});

export default rootReducer;
