import axios from 'axios';
import Cookies from 'js-cookie';
import qs from 'qs';
import { store } from './redux/storeConfig/store';

const clientApi = {
    login: (linkHash, isPayment) => {
        if (isPayment) {
            return makePostRequest('/authentication/authenticate', {
                paymentLinkHash: linkHash,
            });
        }
        return makePostRequest('/authentication/authenticate', { linkHash });
    },
    requestAppraisal: (repairOrderId) => {
        return makePostRequest('/repair-order/appraise-click', {
            repairOrderId,
        });
    },
    logFinanceRequest: (repairOrderId) => {
        return makePostRequest('/repair-order/finance-click', {
            repairOrderId,
        });
    },
    financeRequest: () => {
        return Promise.resolve({ success: true });
    },
    logCouponRequest: (repairOrderId) => {
        return makePostRequest('/repair-order/coupon-click', {
            repairOrderId,
        });
    },
    submitRating: (repairOrderReviewID, rating) => {
        return makePostRequest('/my-review/outcome', {
            repairOrderReviewID,
            rating,
            platform: 'google',
        });
    },
    authorizeVisit: (signature, roId) => {
        return makePostRequest('/repair-order-waiver/signed', {
            repairOrderId: roId,
            signature,
        });
    },
    acknowledgeWaiver: (roId) => {
        return makePostRequest('/repair-order-waiver/acknowledged', {
            repairOrderId: roId,
        });
    },
    getRO: () => {
        const linkHash = store.getState().auth.linkHash;
        return makeGetRequest(`/repair-order/link-hash/${linkHash}`);
    },
    getRos: (params) => {
        return makeGetRequest(`/repair-order`, params);
    },
    getSettings: () => {
        return makeGetRequest(`/settings`);
    },
    getVideos: (repairOrderID) => {
        return makeGetRequest(
            `/repair-order-video?repairOrderID=${repairOrderID}`
        );
    },
    getCoupons: () => {
        return makeGetRequest(`/coupons`);
    },
    getPayment: (linkHash) => {
        return makeGetRequest(`/payment/link-hash/${linkHash}`);
    },
    getRewardsTiers: () => {
        return makeGetRequest(`/rewards-tier`);
    },
    getRoPayment: (paymentId) => {
        return makeGetRequest(`/repair-order-payment/${paymentId}`);
    },
    getRoPayments: (repairOrderID) => {
        return makeGetRequest(
            `/repair-order-payment?repairOrderId=${repairOrderID}`
        );
    },
    getConditions: () => {
        return new Promise((resolve) =>
            resolve({
                status: 200,
                results: [
                    {
                        name: 'Fair',
                        description:
                            '18% of cars valued. Has some cosmetic defects that require repairing and/or replacing',
                    },
                    {
                        name: 'Good',
                        description:
                            '54% of cars valued. Has some repairable cosmetic defects and is free of major mechanical problems',
                    },
                    {
                        name: 'Very Good',
                        description:
                            '23% of cars valued. Has minor cosmetic defects and is in excellent mechanical condition',
                    },
                    {
                        name: 'Excellent',
                        description:
                            '3% of cars valued. Looks new and is in excellent mechanical condition',
                    },
                ],
            })
        );
    },
    getTrims: (hash) => {
        return new Promise((resolve) =>
            resolve({
                status: 200,
                results: [
                    {
                        name: 'Lexus RX RX 350L Sport Utility 4D AWD',
                        vehicleId: 432172,
                        vehicleOptionId: 8178725,
                    },
                    {
                        name: 'Lexus EX 150L All Terrain Utility 4D AWD',
                        vehicleId: 432172,
                        vehicleOptionId: 8178725,
                    },
                    {
                        name: 'Lexus MX  250L Sportsball 2D AWD',
                        vehicleId: 432172,
                        vehicleOptionId: 8178725,
                    },
                    {
                        name: 'Lexus FX 50L Truck 3D AWD',
                        vehicleId: 432172,
                        vehicleOptionId: 8178725,
                    },
                    {
                        name: 'Lexus DMX  450L Lambo 2 Tha Moon',
                        vehicleId: 432172,
                        vehicleOptionId: 8178725,
                    },
                ],
            })
        );
        // return makePostRequest(
        //     'https://staging.iserviceauto.com/api/upgrade/process-vehicle',
        //     { hash }
        // );
    },
    createMyLoan: (body) => {
        return makePostRequest('/my-loan', body);
    },
    requestMyLoanApplication: (body) => {
        return makePostRequest('/my-loan/request-application', body);
    },
    requestCashOffer: (condition, trim) => {
        return new Promise((resolve) => resolve({ status: 200 }));
    },
    makeRoPayment: (body) => {
        return makePostRequest(`/repair-order-payment/pay`, body);
    },
    makeNonRoPayment: (body) => {
        return makePostRequest(`/payment/pay`, body);
    },
    // errors in the "viewed" requests should not be seen by the user
    viewedMpi: (repairOrderMPIID) => {
        return makePostRequest('/repair-order-mpi/view', { repairOrderMPIID });
    },
    viewedQuote: (repairOrderQuoteID) => {
        return makePostRequest('/repair-order-quote/view', {
            repairOrderQuoteID,
        });
    },
    updateQuote: (id, body) => {
        return makePutRequest(`/repair-order-quote/${id}`, body);
    },
    completeQuote: (repairOrderQuoteID, recommendations) => {
        return makePostRequest('/repair-order-quote/complete', {
            repairOrderQuoteID,
            recommendations,
        });
    },
    viewNonRoPayment: (body) => {
        return makePostRequest(`/payment/view`, body);
    },
    viewedReview: (repairOrderReviewID) => {
        return makePostRequest(`/my-review/view`, {
            repairOrderReviewID,
        });
    },
    viewedVideo: (repairOrderVideoID) => {
        return makePostRequest(`/repair-order-video/view`, {
            repairOrderVideoID,
        });
    },
    viewedWaiver: (repairOrderId) => {
        return makePostRequest(`/repair-order-waiver/viewed`, {
            repairOrderId,
        });
    },
    sendReceipt: (repairOrderPaymentId, email) => {
        return makePostRequest('/repair-order-payment/send-receipt', {
            repairOrderPaymentId,
            email,
        });
    },
};

const getSubdomain = () => {
    return process.env.REACT_APP_ENVIRONMENT === 'development'
        ? process.env.REACT_APP_DEVELOPMENT_API_SUBDOMAIN
        : process.env.REACT_APP_ENVIRONMENT === 'staging'
        ? process.env.REACT_APP_STAGING_API_SUBDOMAIN
        : process.env.REACT_APP_ENVIRONMENT === 'production'
        ? process.env.REACT_APP_PRODUCTION_API_SUBDOMAIN
        : process.env.REACT_APP_DEVELOPMENT_API_SUBDOMAIN;
};

async function makePostRequest(endpoint, data) {
    const body = qs.stringify(data);
    const url = `https://${getSubdomain()}.iserviceauto.com/api${endpoint}`;
    const appToken = Cookies.get('iservice_customer_app_token');
    const config = { timeout: 10000 };

    if (appToken) {
        config.headers = { Authorization: `Bearer ${appToken}` };
    }

    return axios.post(url, body, config);
}

async function makePutRequest(endpoint, data) {
    const body = qs.stringify(data);
    const url = `https://${getSubdomain()}.iserviceauto.com/api${endpoint}`;
    const appToken = Cookies.get('iservice_customer_app_token');
    const config = { timeout: 10000 };

    if (appToken) {
        config.headers = { Authorization: `Bearer ${appToken}` };
    }

    return axios.put(url, body, config);
}

async function makeGetRequest(endpoint, params) {
    const url = `https://${getSubdomain()}.iserviceauto.com/api${endpoint}`;
    const appToken = Cookies.get('iservice_customer_app_token');
    const config = { timeout: 10000 };

    if (appToken) {
        config.headers = { Authorization: `Bearer ${appToken}` };
    }

    if (params) {
        config.params = params;
    }

    return await axios.get(url, config);
}

export default clientApi;
