import { toast } from 'react-toastify';

export const showToast = (message = '', type = 'success') => {
    toast[type](message, {
        position: 'top-right',
        autoClose: type === 'error' ? 10000 : 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

export const showErrorToast = (err) => {
    const message = err.message || `Something went wrong :(`;
    showToast(message, 'error');
};
