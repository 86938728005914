import clientApi from '../../../clientApi';
import { getRO } from './index';


// Toggle Cards
export const toggleMpi = (mpiId) => async (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_MPI' });

    if (getState().dashboard.viewedMpi) return;

    try {
        await clientApi.viewedMpi(mpiId);

        dispatch(getRO());
    } catch (err) {
        // Fail silently to the user
        console.error(err);
    }
};
export const toggleQuote = (quote) => async (dispatch, getState) => {
    const viewedQuote = !!getState().dashboard.viewedQuote;

    dispatch({ type: 'TOGGLE_QUOTE' });
    if (viewedQuote) return;

    try {
        await clientApi.viewedQuote(quote.id);
    } catch (err) {
        // Fail silently to the user
        console.error(err);
    }
};
export const toggleReview = (repairOrderReview) => async (dispatch) => {
    dispatch({ type: 'TOGGLE_REVIEW' });

    if (repairOrderReview.dateViewed) return;

    try {
        await clientApi.viewedReview(repairOrderReview.id);
    } catch (err) {
        // Fail silently to the user
        console.error(err);
    }
};
export const toggleVideo = (video) => async (dispatch, getState) => {
    const { visibleVideoId } = getState().dashboard;
    dispatch({ type: 'TOGGLE_VIDEO', videoId: video.id });

    if (visibleVideoId) return;

    try {
        await clientApi.viewedVideo(video.id);
        dispatch(getRO());
    } catch (err) {
        // Fail silently to the user
        console.error(err);
    }
};
export const toggleWaiver = () => async (dispatch, getState) => {
    dispatch({ type: 'TOGGLE_WAIVER' });

    if (getState().dashboard.waiverSignature) return;

    try {
        await clientApi.viewedWaiver(getState().dashboard.repairOrder?.id);
    } catch (err) {
        // Fail silently to the user
        console.error(err);
    }
};